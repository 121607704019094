/*
    Theme Name: Roysha
    Theme URI: http://themeies.com
    Author: Themeies
    Author URI: http://themeies.com
    Version: 2.0
    Description: Roysha is a Landing HTML5 Template.
*/

/*
    Table of Context
    -------------------------------
    01. Common CSS
    02. Content Box
    03. Video Box
    04. Icon Box
    05. Order, Unorder List
    06. Round One side
    07. Overlap Image Box
    08. Image Box
    09. Icon list block
    
    01. Header style
    02. Banner style
    03. Send money 
    04. Receive money 
    05. Payment section
    06. Features section
    07. Testimonial section
    08. Currency form
    09. Bill form 
    10. Receive Form
    11. Services section
    12. Access Money section
    13. Online payment section
    14. Video section
    15. Partners section
    16. App section
    16. Bill Categories
    17. Our process section
    18. Accordion section
    19. Payment system works section
    20. Landing overview section
    21. System works section
    22. Common tab navigation
    23. List tab nav
    24. Benefits section
    25. Fun facts section
    26. Package section
    27. Package Table
    28. About overview section
    29. Mission & Vision section
    30. Banking partners section
    31. Team section
    32. Faq page section
    33. Contact section
    34. Contact Media section
    35. Blog Post section
    36. Pagination
    37. Breadcrumb
    38. Sidebar
    39. Blog single post section
    40. 434 Error section
    41. Signup Login form section
    42. Coming section
    43. Signup Login form section 
    44. Footer
*/


@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700&display=swap');
@import 'reset';
@import 'variable';

/**
 *
 * Common CSS
 *
 */

section{
    @include media(767) {
        padding-bottom: 50px;
    }
}


 @include min-media(768) {
    .nopadd-ltf  {
        padding-left: 0;
    }
    .nopadd-rth {
        padding-right: 0;
    }
    .nopadd {
        padding-left: 0;
        padding-right: 0;
    }
}

.nopadd-btm {
    padding-bottom: 0 !important;
}

.btn {
    color: #fff;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    line-height: 0.8;
    text-transform: capitalize;
    min-width: 200px;
    padding: 20px 25px;
    position: relative;
    overflow: hidden;

    @include min-media {
        &:first-of-type {
            margin-right: 25px;
        }
    }
    &-filled {
        background-color: #256be6;
        &:hover {
            background-color: $clrbluedeep;
            // color: #fff;
            box-shadow: 0 0 30px 5px rgba(0,0,0,0.05);
            i{
                color:#000;
                position: relative;
                z-index: 9;
            }
        }
    }

    &-default {
        background-color: #256be6;
        border-radius: 50px;
        color: #fff;
        transition: all .5s;
        &:hover {
            // background-color: #fff;
            box-shadow: 0 0 30px 5px rgba(0,0,0,0.05);
            // transform:skewY(15deg);
            
        }
    }
    &-round {
        border-radius: 50px;
    }

    &-outline {
        border: 2px solid #fff;
    }

    &:focus {
        box-shadow: none;
    }

    &.icon-left i {
        line-height: .8;
        margin-right: 20px;
    }
    span{
        position: relative;
        z-index: 2;
    }

     span.bh {
        background: #fff;
        border: 10px solid #ffffff8f;
        border-radius: 50%;
        display: block;
        height: 0;
        position: absolute;
        transform: translate(-50%, -50%);
        transition: width 0.7s ease 0s, height 0.7s ease 0s;
        width: 0;
        z-index: 1;
        opacity: 0;
        
    }
    &:hover{
        span.bh{
            height: 500px;
            width: 500px;
            opacity: 1;
        }        
    }
    
}
.circle-cursor {
    pointer-events: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    background: #000;
    font-size: 20px;
    text-align: center;
    transition: linear .2s;
    z-index: 9999999;
    top: 0;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .5;
}

.tagline {
    color: $clrblue;
    display: block;
    font-size: 14px;
    font-weight: $fontMid;
    text-transform: uppercase;
    margin-bottom: 10px;
    position: relative;
    @include media (991) { font-size: 12px; }
    &.bar {
        padding-left: 70px;
        &:after {
            background-color: $clrblue;
            @include pseudo(50%);            
            transform: translateY(-50%);
            height: 2px;
            width: 50px;
        }
    }
}
@include min-media {
    .mb100 {
        margin-bottom: 100px;
    } 
}
.bg-offwhite {
    background-color: $clroffWhite;
}
.sec-heading {
    text-align: center;
    margin-bottom: 76px;
    .sec-title {
        color: $clrlightBlack;
        font-size: 36px;
        line-height: 48px;
        text-transform: capitalize;
        margin-bottom: 0;

        @include media(767) {
            font-size: 28px;
            line-height: 36px;
        }
    }
    .sec-subtitle {
        color: #6c6c6f;
        font-size: 16px;
        line-height: 28px;
        margin-top: 16px;
        margin-bottom: 0;
    }
}

.avatar {
    border-radius: 50%;
}


 
// Roysha Icon
.roysha-icon {
    display: inherit;
    font-size: 70px;
    margin-bottom: 20px;
    span:before{ 
        color: #256be6  !important
    }
}



/**
 *  Preloader
 */
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f5f5f5;
    z-index: 99999999;
    width: 100%;
    height: auto;
}

#status {
    width: 250px;
    height: 250px;
    position: absolute;
    left: 50%;
    top: 50%;
    background-image: url(../images/preloader.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}



/**
 *  Content Box
 */
.content-box {
    @include media(767) {
        margin-bottom: 50px;
    }
    h2 {
        margin-bottom: 20px;
        @include media(991) { font-size: 27px; }
    }

    h4 { color: #262934; }

    p { margin-bottom: 28px; }

    // .btn { margin-top: 24px; }

    .listBox {
        margin-bottom: 35px;
        padding-left: 80px;
        
        img, .roysha-icon {
            left: 0;
            top: 0;
            transform: none;
            max-width: 60px;
        }
    }
}

.content-box-fluid {
    @extend .content-box;
    @include min-media { 
        max-width: 570px;
    }
    // @include min-media(1200) { padding-left: 30px }
    // @include media(767) { margin-bottom: 50px; }

    &.right {
        @include min-media(1200) { 
            float: right;
            padding-left: 30px 
        }
    }
    &.left {
        float: left;
        @include min-media(1200) { padding-right: 25px; }
    }
}

// Video Box
.video-box {
    position: relative;

    &:after {
        @include transBlue(.5);
        @include pseudo;
        height: 100%;
        width: 100%;
        opacity: .2;
    }

    &-round {
        @extend .video-box;
        border-radius: 5px;
        overflow: hidden;
    }

    .video-btn {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }
}

// Beat animation
@keyframes beat {
    0% {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(2);
                transform: scale(2);
    }
}

.video-btn {
    background-color: #fff;
    border-radius: 50%;
    color: #256be6;
    display: block;
    text-align: center;
    line-height: 60px;
    margin: 0 auto;
    position: relative;
    height: 60px;
    width: 60px;
    z-index: 1;

    i {
        position: relative;
        z-index: 1;
    }

    &:before {
        @include pseudo;
        -webkit-animation: beat infinite 1.2s;
        animation: beat infinite 1.2s;
        height: 100%;
        width: 100%;
        background-color: $clrblue;
        border-radius: 50%;
        z-index: -1;
    }
    &:after {
        @include pseudo;
        background-color: #fff;
        border-radius: 50%;
        height: 100%;
        width: 100%;
    }
}

// Icon Box
.iconBox {
    @include media(767) { margin-bottom: 30px; }
    @include media(575) { text-align: center; }
    position: relative;
    // transition: $transition;

    img {
        max-width: 70px;
        margin-bottom: 25px;
        @include media(575) { margin: 0 auto 25px; }
    }

    .icon {
        color: $clrblue;
        font-size: 5em;
        margin-bottom: 20px;
    }

    h5 {
        color: $clrlightBlack;
        font-size: 22px;
        text-transform: capitalize;
        margin-bottom: 15px;
        a {
            color: $clrlightBlack;
            display: block;
            @include media(991) { font-size: 18px; }
        }
    }
    p {
        margin-bottom: 0;
        @include media(991) { 
            font-size: 14px; 
            line-height: 22px;
        }
    }
    .btn {
        @extend .btn-round;
        background-color: #2dbe61;
        margin-top: 44px;

        &:first-of-type{
            margin-top: 25px;
            margin-right: 0;
        }

        @include media-between(768, 991) {
            min-width: inherit;
            width: 100%;
        }
    }
}
.boxed {
    padding-top: 0;
    padding-bottom: 0;
    &-shadow {
        background-color: #fff;
        box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.05); 
        padding: 50px 30px;
        // transition: unset;
        @include media(991) { padding: 35px 15px; }
    }
    img,
    &-shadow img {
        margin: 0 auto 35px;
    }
}

.listBox {
    padding-left: 120px;
    text-align: left;
    
    img, .roysha-icon {
        position: absolute;
        top: 50%;
        left: 25px;
        transform: translateY(-50%);
    }

    &-shadow {
        @extend .listBox;
        background-color: #fff;
        box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        padding: 30px 25px 22px 120px;
    }
}

// Order, Unorder List
.list {
    margin-bottom: 30px;

    li  { 
        color: #2f2f35;
        padding-left: 30px;
        position: relative;
    }
    i {
        color: #2dbe61;
        font-size: 13px;
        margin-right: 10px;
        position: absolute;
        left: 0;
        top: 8px;
    }
}

// Round One side
.round-os {
    border-top-left-radius: 315px;
    border-bottom-left-radius: 315px;
    overflow: hidden;
}

// Overlap Image Box
.overlap-imgBox {
    position: relative;

    .img-1, .img-2 {
        width: 100%;
    }
    .abs-img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    .btn {
        background-color: #fff;
        border-radius: 0;
        border-bottom-left-radius: 10px;
        color: $clrblue;
        font-size: 30px;
        min-width: inherit;
        padding: 10px 0;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100px;
        z-index: 2;

        &:hover {
            background-color: #256be6;
            // color: #fff;
        }
    }
}

// Image Box
.imgBox {
    display: block;
    position: relative;
    width: 100%;

    @include media(767) {
        margin-bottom: 50px;
    }
}

// Icon list block
.icon-list-block {
    background-color: #fff;
    box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.05);
    border: 1px solid #efefef;
    border-radius: 5px;
    display: block;
    padding: 15px 25px;
    margin-bottom: 30px;
    @include media(991) {
        text-align: center;
        padding: 15px;
    }

    &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
    }

    img {
        display: inline-block;
        margin-right: 20px;
        width: 30px;

        @include media(1199) {
            margin-right: 15px;
        }
        @include media(991) {
            display: block;
            margin: 0 auto;
        }
    }

    // if use icon insted of image
    i {
        display: inline-block;
        margin-right: 20px;
        font-size: 20px;
    }

    span {
        color: #2f2f35;
        display: inline-block;
        font-size: 18px;
        font-weight: 500;
        @include min-media(992) { line-height: .8; }        
        text-transform: capitalize;
        margin-bottom: 0;

        @include media(991) {
            line-height: 1
        }
        @include media(1199) {
            font-size: 15px;
        }
    }
}





/**
 * Header style
 */
.header {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 88px;
    width: 100%;
    z-index: 9;
    transition: all .5s;
    @include transBlack(.1);

    &.fixed-header{
        position: fixed;
        background: $clrbluedeep;
        // height: 70px;
    }

    .container {
        position: relative;
        height: 100%;
    }

    .logo {
        display: block;
        max-width: 138px;
    }
    .primary-menu {
        margin-left: auto;

        ul {
            @include media(991) {
                background-color: #256be6;
                display: none;
                text-align: left;
                width: 100%;

                &:not(.sub-menu) {
                    max-height: 400px;
                    overflow-y: scroll;
                    padding: 10px 0;
                    position: absolute;
                    top: 100%;
                    left: 0;
                }
            }

            li {
                display: inline-block;
                position: relative;

                &.login {
                    @include media(991) {
                        border: none;
                    }
                    a {
                        @include min-media {                    
                            padding: 5px 30px;
                        }
                    }
                }                
                &.has-menu-child {
                    i {

                        color: #fff;
                        cursor: pointer;
                        display: block;
                        font-size: 12px;
                        line-height: 20px;
                        text-align: center;
                        position: absolute;
                        right: 10px;
                        top: 5px;
                        width: 20px;
                        height: 20px;

                        @include min-media(992) {
                            right: -5px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }

                    ul.sub-menu {
                        padding-left: 15px;
                        border: 1px solid #ddd;

                        @include min-media(992) {
                            background-color: #fff;
                            text-align: left;
                            transition: $transition;
                            padding: 0;
                            position: absolute;
                            opacity: 0;
                            visibility: hidden;
                            top: 100%;
                            left: 0;
                            width: 220px;
                        }
                        li {
                            display: block;
                        }

                        a {
                            @include min-media {
                                color: #666;
                                font-size: 14px;
                                padding: 8px 5px 10px 25px; 
                                position: relative
                            }

                            &:before{
                                content: '';
                                width: 7px;
                                height: 1px;
                                border-radius: 50%;
                                background: $clrbluedeep;
                                display: block;
                                position: absolute;
                                left: 10px;
                                top: 20px;
                                transform: scale(1);
                                transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                            }
                            

                            &:hover {
                                background-color: $clrbluedeep;
                                color: #fff
                            }
                            &:hover:before{
                                background: #fff;
                                transform: scale(2)
                            }
                           
                        }
                    }
                }                 
                a {
                    color: #fff;
                    display: block;
                    text-transform: capitalize;
                    padding: 5px 25px;
                    position: relative;

                    @include min-media {
                        padding: 30px 15px;
                    }

                    @include media(1199) { 
                        font-size: 14px; 
                    }
                    @include media(767) {
                        // padding: 10px 0;
                        &:hover {
                            background-color: #1053c8;
                        }
                    }
                    &.login.btn.btn-outline.btn-round {
                        padding: 11px 25px !important;
                        @include min-media(992) {
                            min-width: auto;
                            top: 13px;
                            position: relative;
                            &:hover span{
                                color: $clrbluedeep
                            }
                        } 
                        @include media(991) {
                            border: none;
                            text-align-last: left;
                            font-weight: 400;

                            .bh {display: none;}
                        }      
                    }
                }

                &:hover {
                    ul.sub-menu {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                @include media(991) {
                    display: block;
                }
            }
        }

        #mobile-menu-toggler {
            color: #fff;
            cursor: pointer;
            font-size: 20px;
            line-height: .8;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);

            @include min-media(992) { display: none; }
        }
        
    }
}

.header02 {
    @extend .header;
    background-color: #fff;
    position: relative;
    height: auto;


    .logo {
        img {
            filter: brightness(0) invert(0);
        }
    }

    .primary-menu {
        margin-left: 0;

        #mobile-menu-toggler {
            color: #000;
        }

        ul {
           li {
                &:last-child {
                    margin-left: auto;
                } 
                a {
                    @include min-media(992) {
                        color: #000;
                    }
                }
            } 
        }       

        .btn {
            @include min-media(992) {
                background-color: #004bc8;
            }  
        }
    }
}

.header03 {
    @extend .header02;
    .social-icons {
        display: inline-block;
        
        a:not(:last-child) {
            border-right: 1px solid #fff;
        }
    }
    .login {
        padding: 5px 20px;
        border-radius: 0;
        text-transform: capitalize;
        &:hover {
            color: #fff;
        }
        @include media(767) {
            padding: 5px 13px;
        }
    }
}

.header04 {
    @extend .header;

    .login {
        min-width: auto;
        padding: 7px 25px;
        border-radius: 5px;
        text-transform: capitalize;
        letter-spacing: 1.8px;

        &:hover {
            color: $clrlightBlack;
            background-color: #fff;
        }

        @include media(991) {
            margin-right: 35px;
        }
    }
}

.header-main {
    @include media(991) {
        // padding: 20px 0;
        height: 80px;
    }
}
.header-top {
   padding: 10px 0;
   background: #002563;
   
   .top-text {
       display: inline-block;
       color: #fff;
       font-size: 12px;

       &:nth-child(2) {
           margin-left: 20px;
       }

       i {
           margin-right: 5px;
       }
   }

   .social-icons {
       a {
           display: inline-block;
           color: #fff;
           font-size: 13px;
           line-height: 1;
           padding: 0 7px;
       }
   }

   @include media(575) {
       display: none;
   }
}

.primary-menu {
    .btn {
        span {
            color: #fff;
        }

        &:first-of-type {
            margin-right: 0;
        }
    }
}

/**
 * Banner style
 */
.banner {
    @include overlay( $i:'../images/banner/1.jpg');
    @include bg-props;
    @include min-media { padding: 10% 0 5%; }
    @include media(991) { padding: 15% 0 7%}
    @include media(767) { padding: 20% 0 10%}
    @include media(479) { padding: 150px 0 50px;}
    .ban-content {
        // margin-bottom: 50px;

        h1 {
            color: #fff;
            font-size: 46px;
            line-height: 60px;
            text-transform: capitalize;
            margin-bottom: 0;
            @include media(1199) { 
                font-size: 38px;
                line-height: 50px;
            }
            @include media(767) {
                font-size: 34px;
                line-height: 48px;
            }
            @include media(575) { 
                font-size: 30px;
                line-height: 40px;
            }
            @include media(479) { 
                font-size: 22px;
                line-height: 30px;
            }
        }
        p {
            color: #fff;
            @include min-media { line-height: 30px; }
            @include min-media(576) { margin-bottom: 5%; }
            @include media(575) { 
                font-size: 14px;
                margin-bottom: 40px;
            }
            @include media(479) { font-size: 13px }
        }
    }
    .btn {
        @include media(575) { font-size: 13px; }
        @include media(479) { margin-bottom: 15px; }
     }
    @include media(1199) { br {display: none;} }

    &:before{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #004bc8;
        opacity: .7;
    }
    &.v2 {
        @include overlay( $i:'../images/banner/4.jpg');

        &:after {
            background-color: #fff;
            clip-path: polygon(100% 65%, 100% 100%, 0 100%);
            @include pseudo;
            height: 100%;
            width: 100%;
        }

        .tagline {
            color: #fff;
            font-weight: 400;
        }

        &.bSupport {
            &:after {
                background-color: transparent !important;
            }
        }
    }

    &.v3 {
        @include overlay( $i:'../images/banner/5.jpg');
    }

    &.v5 {
        // @include overlay($c1:rgba(#5134dc, .9), $c2:rgba(#004bc8, .9), $i:'../images/banner/8.jpg');
        @include overlay($i:'../images/banner/8.jpg');
    }

    &.v6 {
        @include overlay( $i:'../images/banner/10.jpg');
        // @include overlay($c1:rgba(#5134dc, .85), $c2:rgba(#004bc8, .85), $i:'../images/banner/10.jpg');
    }

    &.v7 {
        // @include overlay($c1:rgba(#123d88, .85), $c2:rgba(#123d88, .85), $i:'../images/banner/10.jpg');
        @include overlay( $i:'../images/banner/10.jpg');
    }
    &.v7:before{ 
        opacity: .7;

        // @include overlay( $i:'../images/banner/10.jpg');

        .ban-content {
            @include media(991) {
                margin-bottom: 40px;
            }
        }
    }
    .card-scrn-img {
        max-width: 1825px;
        margin: 0 auto;
        width: 100%;
    }

    .simple-txt {
        color: #fff;
        display: block;
        margin-top: 20px;
    }
}

// Home 2 banner
.home-two {
    .banner {
        &::before {
            @include pseudo($t:auto, $b:0px);
        }
    }
}

.overview {
    .content-box {
        @include media(991) {
            margin-bottom:50px;
        }
    }
    
    .iconBox {
        margin-bottom: 30px;
    }
}


/**
 * Send money 
 */
.home-send-money {
    background-color: $clroffWhite;
    .mbl-screen {
        &:before {
            // background-image: url(../images/mbl-scrn-bg.png);
            @include bg-props(auto, $p: left top);
            @include pseudo($t:50%, $l:auto, $r:100%);
            transform: translateY(-50%);
            height: 379px;
            width: 147px;
            z-index: 1;
        }
    }
}

.mbl-screen {
    position: relative;
    overflow: hidden;
    .mbl-scrn1 {
        max-width: 313px;
        @include min-media(320) { margin-left: -70px; }
    } 
    .mbl-scrn2 {
        position: absolute;
        right: 0;
        top: 55px;
        max-width: 309px;
        z-index: 1;
    }
    @include media(991) { margin-bottom: 50px; }
}

/**
 * Receive money 
 */
 .home-recv-money {
    overflow: hidden;
    .recv-img {
        position: relative;
        @include min-media(992) { padding-left: 10% }
    }
 }

/**
 * Payment section
 */
.payment {
    .pay-img {
        max-width: 593px;
        position: relative;
        @include min-media(1400) { margin-left: -120px; }
        @include media(991) { margin-bottom: 50px; }
    }
}
 
/**
 * Features section
 */
 .features {
    @include overlay($i:'../images/banner/2.jpg');
    @include bg-props;
    padding-bottom: 0;
    overflow: hidden;
    &:before{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #004bc8;
        opacity: .7;
    }
    .sec-heading {
        max-width: 770px;
        margin: 0 auto;
        .sec-title,
        .sec-subtitle { color: #fff; }
    }
    .features-scrns {
        position: relative;
        max-width: 1570px;
        margin: 0 auto;
        .scrn-1 {
            max-width: 892px;
            margin: 0 auto;
            position: relative;
            z-index: 1;
            // bottom: -40px;
            // transition: all .5s;
            &:hover{
                // bottom: 0
            }
        }
        .scrn-2,
        .scrn-3 {
            max-width: 838px;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 0;
            bottom: -40px
        }
        .scrn-3 {
            left: auto;
            right: 0;
        }
    }

    &-v2 {
        @include overlay( $i:'../images/banner/7.jpg');
        @include bg-props;

        .content-box-fluid {
            h2, p, span {
                color: #fff;
            }
            .tagline.bar {
                &:after { background-color: #fff; }
            }
            a:hover span{
                color: #000
            }
        }
    }
 }

 /**
 * Testimonial section
 */
 .testimonial {
     .single-testimonial {
         position: relative;
         margin-bottom: 65px;
     }

    .tst-body {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1); 
        padding: 26px 20px;
        position: relative;
        margin-bottom: 27px;
        &:after {
            @include pseudo($t:100%, $l:24px, $r:auto, $b:auto);
            background-color: #fff;
            clip-path: polygon(0 0, 50% 100%, 100% 0);
            width: 20px;
            height: 13px;            
        }
    }
    .tst-head {
        position: relative;
        padding: 10px 0 10px 85px;
        min-height: 70px;
        .avatar {
            border-radius: 50%;
            max-width: 70px;
            position: absolute;
            left: 0;
            top: 0;
        }
        h6 {
            color: $clrlightBlack;
            font-size: 16px;
            font-weight: 500;
            text-transform: capitalize;
            margin-bottom: 0;
        }
        span {
            color: #6c6c6f;
            font-size: 16px;
        }
    }

    .owl-dots {
        text-align: center;

        span {
            background-color: #c0c0c2;
            display: inline-block;
            margin: 0 7px;
            height: 4px;
            width: 40px;
        }

        .active span { background-color: $clrblue; }
    }

    // Version 2
    &-v2 {
        .testimonialBox {
            position: relative;
            background-color: #fff;
            border-radius: 5px;
            box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.05);
            padding: 30px;
            text-align: center;

            .quote-sign {
                color: #f8f8f8;
                font-size: 62px;
                position: absolute;
                top: 30px;
                left: 30px;
                z-index: 1;
            }
        }

        .single-testimonial {
            position: relative;
            max-width: 770px;
            margin: 30px auto;

            p {
                font-style: italic;
                margin: 0;
            }
        }

        .client-thumb {
            a {
                border-radius: 50%;
                display: inline-block;
                margin: 0 10px;
                width: 42px;
                overflow: hidden;
                
                &.active {
                    transform: scale(1.3);
                }
            }
        }

        .owl-nav {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;

            @include media(991) {
                display: none;
            }

            button {
                background-color: #cacaca !important;
                border-radius: 50%;
                color: #fff !important;
                transition: $transition;
                height: 40px;
                width: 40px;

                &:hover {
                    background-color: #256be6 !important;
                }
            }            

            .owl-prev {
                position: absolute;
                left: -130px;
            }
            .owl-next {
                position: absolute;
                right: -130px;
            }
        }
    }

    &-v3 {
        @extend .testimonial-v2;

        .testimonialBox {
            box-shadow: none;

            .tst-head {
                .avatar {
                    display: block;
                    margin: 0 auto 15px;
                    width: 60px;
                }

                h6 {
                    color: #2f2f35;
                    font-size: 20px;
                    margin-bottom: 27px;
                }
            }
        }

        .owl-dots {
            button {
                border: 1px solid #737376;
                border-radius: 50px;
                margin: 0 4px;
                height: 10px;
                width: 10px;

                &.active {
                    background-color: #256be6;
                    border-color: #256be6;
                }
            }
        }
    }
 }

/**
 * Footer
 */
 .footer {
     @include bg-props($s:auto);
     background-image: url('../images/footer-bg.png');
     background-color: #123d88;
     position: relative;     
    .foo-top {
        padding: 100px 0 90px;
    }
    .widget {
        h5 {
            color: #fff;
            font-size: 20px;
            position: relative;
            padding-bottom: 15px;
            margin-bottom: 25px;

            &:before {
                @include pseudo($b:0, $t:auto);
                background-color: #fff;
                height: 2px;
                width: 30px;
            }

            i {
                @include media(575) {
                    font-size: 14px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 20px;
                    text-align: center;
                }
            }
        }

        @include media(575) { 
            margin-top: 30px;
        }

    }
    .foo-nav { 
        @include media(991) {
            margin-bottom: 30px;
        }
        @include media(575) {
            margin-bottom: 0;

            ul { display: none; }
            h5 { 
                cursor: pointer;
                margin-bottom: 0;
            }
        }
        li {
            a {
                color: #fff;
                display: block;
                font-size: 15px;
                padding: 5px 0;
                padding-left: 10px;
                position: relative;

                &:before {
                    content: '\f0da';
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    position: absolute;
                    left: 0;
                    top: 5px;
                    opacity: 1;
                    visibility: visible;
                    // transform: scale(1);
                    transition: $transition;
                }

                &:hover {
                    padding-left: 15px;

                    // &::before {
                    //     opacity: 1;
                    //     visibility: visible;
                    //     transform: scale(1);
                    // }
                }
            }                
        }
        
    }
    .foo-address {
        address {
            color: #fff;
            margin-bottom: 35px;
            & a {
                color: #fff;
            }
        }
    }
    .foo-btm {
        @include transBlack(.1);
        padding: 25px;        
    }
    .foo-navigation {
        ul {
            li {
                display: inline-block;
                a {
                    color: #fff;
                    display: block;
                    font-size: 15px;
                    padding-right: 20px;
                    
                    @include media(991) {
                        font-size: 12px;
                        padding-right: 12px;
                    }
                }
                &:last-child {
                    a { padding-right: 0; }                    
                }
            }                
        }
        @include media(767) {
            text-align: center;
        }
    }
    .copyright {
        color: #fff;
        font-size: 15px;
        text-align: center;
        a {
            color: #fff;
            display: inline-block;
            font-weight: $fontMid;
        }
        @include min-media(768) { text-align: right; }
        @include media (991) { font-size: 12px; }
    }
 }
/**
 * Currency form
 */

 .nice-select {
    float: none;
    border: 0;
 }
 .form-field {
    // overflow: hidden;
    position: relative;
    margin-bottom: 20px;
    label {
        color: #6c6c6f;
        display: block;
        font-size: 16px;
        font-weight: $fontMid;
        text-transform: capitalize;
        margin-bottom: 7px;
    }
    input {            
        border: none;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        padding: 10px 15px;
        width: 100%;
    }
    select, .nice-select {
        appearance: none;
        border: 1px solid #dadada;
        border-radius: 5px;
        display: block;
        // padding: 9px 15px;
        width: 100%;
    }

    .nice-select {
        width: 100%;
        height: 50px;
        line-height: 50px;

        .list {
            width: 100%;
        }
    }

    .curr-select {
        border: 1px solid #dadada;
        border-radius: 5px;
        flex-basis: 30%;
        position: relative;
        padding: 10px 15px;

        img {
            display: inline-block;
            max-width: 25px;
            height: 25px;
            margin-right: 10px;   
            vertical-align: top;
        }
        span, a {
            color: $clrlightBlack;
            display: block;
            font-size: 16px;
            text-transform: uppercase;
            position: relative;
        }
        ul {
            background-color: #fff;
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1); 
            display: none;
            padding: 10px 20px;
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;
            z-index: 1;

            li:first-child { margin-bottom: 10px; }
        }
    }
    .join-field {
        align-items: center;
        border: 1px solid #dadada;
        border-radius: 5px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        // overflow: hidden;

        .nice-select {
            width: auto;
            border: none;
            border-left: 1px solid #dadada;
            border-radius: 0;
            @include media(575) {
                width: 100%;
                border-left: 0;
                border-top: 1px solid #dadada;
            }
        }

        input, select {
            flex-basis: 70%;
            max-width: 70%;

            @include media-between(992, 1199) {
                flex-basis: 60%;
                max-width: 60%;
            }

            @include media(479) {
                flex-basis: 50%;
                max-width: 50%;
            }
        }
        .curr-select {
            flex-basis: 30%;
            max-width: 30%;

            @include media-between(992, 1199) {
                flex-basis: 40%;
                max-width: 40%;
            }

            @include media (479) {
                flex-basis: 50%;
                max-width: 50%;
            }
        }
        select {
            border: none;
            border-left: 1px solid #dadada;
            border-radius: 0;
        }
    }
}

 .currency-form {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 40px 10px rgba(0, 0, 0, .10);
    cursor: pointer;
    padding: 40px 22px;
    position: relative;
    z-index: 1;

    h4 {
        color: $clrlightBlack;
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 34px;
    }
    .form-btn { 
        text-align: left;
        line-height: 1;
        margin-bottom: 30px;
        padding: 20px 40px;
        i { float: right;}
    }
    .accept-terms {
        color: #6c6c6f;
        display: block;
        font-size: 14px;
        line-height: .8;
        text-align: center;

        @include media(1199) {
            a { 
                display: block;
                margin-top: 10px;
            }
        }
    }
    .form-field {
        .curr-select {
            border: none;
            border-left: 1px solid #dadada;;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border-radius: 0;
            padding: 0;
            padding-left: 20px;
        }
    }
 }

.currency-form-v2 {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 40px 10px rgba(0, 0, 0, .10);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 22px 0;
    position: relative;

    h4 {
        flex-basis: 100%;
    }

    .form-field {
        @include media(767) {
            flex-basis: 100% !important;
        }

        &:first-of-type {
            flex-basis: 130px;
        }
        &:nth-of-type(2) {
            flex-basis: calc( 100% - 145px );
        }

        .join-field {
            input, select, .btn {
                flex-basis: 33.333%;
                max-width: 33.333%;
            }
            @include media(575) {
                input, select, .btn {
                    flex-basis: 100%;
                    max-width: 100%;
                }

                select {
                    border-left: 0;
                    border-top: 1px solid #dadada;
                }
            }
        }
        .btn {
            background-color: #2dbe61;
            margin: 0;
            min-width: auto;
            padding: 17px 15px;
        }
    }
}
 
// Bill form 
.bill-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .form-field {
        &:first-of-type {
            flex-basis: 200px;
        }
        &:nth-of-type(2) {
            flex-basis: calc(100% - 208px);
        }

        @include media(575) {
            &:first-of-type {
                flex-basis: 100%;
            }
            &:nth-of-type(2) {
                flex-basis: 100%;
            }
        }


        select, .nice-select{
            border-radius: 0;
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
            color: #2f2f35;
            font-size: 16px;
            font-weight: 500;
            line-height: 40px;
            height: 60px;
            padding: 9px 25px;

            @include media(575) {
                border-radius: 50px;
            }
        }

        input {
            border-radius: 0;
            height: 60px;
            // border-top-right-radius: 50px;
            // border-bottom-right-radius: 50px;
        }

        .join-field {
            border: none;
            display: block;
            
            input {
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
                flex-basis: 100%;
                max-width: 100%;
                padding-right: 90px;
                padding-left: 25px;
                @include media(575) {
                    border-radius: 50px;
                }
            }
            .btn {
                background-color: #2dbe61;
                border-radius: 0;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
                min-width: auto;
                margin: 0;
                position: absolute;
                right: 0;
                top: 0;
                height: 60px;
                width: 75px;

                @include media(575) {
                    border-radius: 50px;
                }
            }
        }
    }

    &.v2 {
        .form-field {
            select, .nice-select {
                border-radius: 50px;
                padding-left: 25px;
                line-height: 44px;
            }

            input {
                border-radius: 50px;
                padding-left: 25px;
                padding-right: 210px;
                @include media(767) {
                    padding-right: 110px;
                }
            }

            .btn {
                border-radius: 50px;
                width: 200px;
                @include media(767) {
                    width: 105px;
                }
            }
        }
    }
}

// Receive Form
.receive-form {
    background-color: #fff;
    border-radius: 5px;
    position: relative;

    .form-field {
        label {
            background-color: #f1f1f1;
            border-top-left-radius: 5px; 
            border-top-right-radius: 5px;
            color: #2f2f35;
            display: block;
            font-size: 16px;
            font-weight: 500;
            text-transform: capitalize;
            padding: 13px 25px;
            margin: 0;
        }

        .join-field {
            border: none;
            .curr-select {
                border: none;
                flex-basis: 68.49%;
                max-width: 68.49%;
                @include media(375) {
                    flex-basis: 100%;
                    max-width: 100%;
                }

                span {
                    text-transform: capitalize;

                    @include media(375) {
                        i {
                            position: absolute;
                            right: 0;
                            top: 5px;
                        }
                    }
                }
            }
            .btn {
                background-color: #2dbe61;
                border-radius: 0;
                border-bottom-right-radius: 5px;
                flex-basis: 31.50%;
                min-width: auto;
                margin: 0;

                @include media(375) {
                    border-bottom-left-radius: 5px;
                    flex-basis: 100%;
                    max-width: 100%;
                }
            }
        }
    }
}

// Tab form
.form-tab {
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;

    .nav-tabs {
        border-bottom: none;

        li {
            flex-grow: 1;
            text-align: center;
        }

        a {
            border: 1px solid #dadada;
            background-color: #f0f0f0;
            color: $clrlightBlack;
            display: block;
            font-size: 18px;
            font-weight: $fontMid;
            padding: 20px 10px;
            text-transform: capitalize;
            
            &.active {
                background-color: #fff;
                border-color: transparent;
            }
        }
    } 

    .currency-form {
        padding-top: 25px;
        box-shadow: none;
    }

    .form-field {
        border: 1px solid #dadada;
        border-radius: 5px;

        label {
            background-color: #f0f0f0;
            border-bottom: 1px solid #dadada;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            color: $clrlightBlack;
            margin-bottom: 0;
            padding: 7px 15px;
        }
        .join-field {
            border: none;
        }
        select, .nice-select {
            border: none;
        }
    }
}

.currency-text {
    align-items: center;
    display: flex;
    position: relative;
    padding-left: 50px;
    min-height: 51px;
    margin-bottom: 20px;

    &::before {
        @include pseudo;
        background-image: url(../images/ellips.png);
        background-repeat: no-repeat;
    }
    strong {
        font-weight: $fontMid;
    }
    span {
        font-size: 14px;

        &:last-child {
            margin-left: 20px;
        }
    }
}


/**
 * Services section
 */
 .services {
    @include min-media(1441) {
        .container-fluid {
            max-width: 1400px;
        }
    }
 }
 
 /**
 * Access Money section
 */
 .access-money {
     .overlap-imgBox {
        .img-1 {
            border-radius: 10px;
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
            overflow: hidden;
            position: relative;
            z-index: 1;

            @include media(767) {
                max-width: calc(100% - 30px)
            }
        }
        .img-2 {
            right: -85px;
            z-index: 0;

            @include media(1199) {
                right: -65px;
            }
            @include media(991) {
                right: -30px;
            }
            @include media(575) {
                right: 0;
            }
        }
     }

     @include media-between(992, 1440) {
        .offset-lg-1 {
            margin-left: 3%;
        }
     }
 }

 /**
 *  Question section
 */
.question {
    .sec-heading {
        margin-bottom: 40px;
    }
}

 /**
 * Online payment section
 */
 .online-payment {
    overflow: hidden;

    .imgBox {
        padding-left: 65px;

        &::before {
            // background-image: url(../images/leaf.png);
            @include bg-props(auto, $p: left top);
            @include pseudo($t:50%, $l:45px);
            transform: translateY(-50%);
            height: 360px;
            width: 109px;
            z-index: 1;

            @include media(1919) {
                @include bg-props(100%, $p:center left);
                height: 95%;
                z-index: -1;
            }
        }


        @include media(767) {
            margin-bottom: 50px;
        }
        @include media(575) {
            padding-left: 0;
            &:before {
                display: none;
            }
        }
    }
 }

  /**
 * Video section
 */
 .video {
    @include overlay( $i:'../images/banner/6.jpg');
    @include bg-props;
    background-attachment: fixed;
    
    .tagline {
        color: #fff;
    }

    .sec-title, .sec-subtitle {
        color: #fff;
    }
    .video-btn {
        margin-bottom: 100px;
    }
    .btn {
        text-align: center;
        margin: 0 auto;
    }

    &.v2 {
        @include overlay($i:'../images/banner/9.jpg');
    }

    &.v3 {
        @include overlay( $i:'../images/banner/11.jpg');
    }
 }
 

.video-post {
    .video-box-round {
        @include media(767) {
            margin-bottom: 30px;
        }
    }
}


 /**
 * Partners section
 */
 .partners {
     .partners-logo {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;	
     }
 }

 /**
 * App section
 */
 .app {
     overflow: hidden;

    .btn {
        padding-top: 17px;
        padding-bottom: 17px;

        @include media(479) {
            width: 100%;
            &:first-of-type {
                margin-bottom: 20px;
            }
        }
    }

    .icon-left {
        i { 
            font-size: 20px;
            margin-right: 10px;
        }
    }
 }

/**
 * Instant Cashout
 */
.instant-cashout,
.online-transfer,
.mobile-wallet {
    overflow: hidden;
}

/**
 * Bill Categories
 */
 .bill-categories {
    padding-bottom: 70px;
 }

 /**
 * Our process section
 */
 .our-process {
    .btn {
        background: #256be6;
        color: #fff;
        margin-top: 65px;
        border: 1px solid #256be6;
        &:hover{
            color: #000;
        }
    }

    .boxed-shadow {
        @include media(1199) {
            padding-left: 15px;
            padding-right: 15px;

            p {
                font-size: 15px;
            }
        }
        @include media(991) {
            margin-bottom: 30px;
        }
    }
 }


 /**
 * Accordion section
 */
 .accordion {
    counter-reset: accordion-counter;
    position: relative;

    @include media(767) {
        margin-bottom: 50px;
    }
    
    .accordion-item {
        background-color: #fff;
        box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.05); 
        border: 1px solid #f1f1f1;
        border-radius: 5px;
        margin-bottom: 20px;
        padding: 20px;
        position: relative;

        h5 {
            color: #2f2f35;
            cursor: pointer;
            font-size: 18px;
            margin-bottom: 0;
        }

        .acr-body {
            margin-top: 12px;

            p {
                margin-bottom: 0;
            }
        }
    }

    &.has-numbering {
        .accordion-item {
            padding-left: 80px;
            &:before {
                background: #fff;
                border-radius: 50%;
                border: 1px solid #e0e0e0;
                counter-increment: accordion-counter;
                content: counter(accordion-counter);
                color: #111;
                display: block;
                line-height: 35px;
                text-align: center;
                position: absolute;
                left: 20px;
                top: 15px;
                height: 35px;
                width: 35px;
                z-index: 1;
            }
    
            &:after {
                background-color: #f6f6f6;
                border: 1px solid #e0e0e0;
                border-radius: 50%;
                content: '';
                display: block;
                position: absolute;
                left:15px;
                top: 10px;
                height: 45px;
                width: 45px;
            }
            &.active{
                &:before {
                    background: #256be6;
                    color: #fff
                }
            }
        }
    }
 }


 /**
 * Payment system works section
 */
 .ps-works {
     .video-box {
        padding: 0 100px;

        @include media(1440) {
            padding: 0 50px;
        }
        @include media(1199) {
            padding: 0 30px;
        }
        @include media(767) {
            padding: 0 15px;
        }

        &:after {
            background-color: #123d88;
            clip-path: polygon(40% 0, 100% 0, 100% 100%, 0 100%);
            max-width: 730px;
            height: calc(100% + 140px);
            top: -70px;
            left: auto;
            z-index: -1;

            @include media(1199) {
                height: calc(100% + 60px);
                top: -30px;
            }
        }
    }
 }

 .faq-tab {
     .nav {
        border: none;
        margin-bottom: 40px;

        a {
            background-color: #fff;
            border: 1px solid #f1f1f1;
            border-radius: 5px;
            color: #2f2f35;
            display: block;
            font-size: 18px;
            font-weight: 500;
            text-transform: capitalize;
            text-align: center;
            padding: 30px 10px 20px;

            img, .roysha-icon {
                display: block;
                width: 70px;
                margin: 0 auto 24px;

                @include media(767) {
                    margin-bottom: 10px;
                }
            }

            &.active {
                background-color: #256be6;
                border-color: #256be6;
                color: #fff;

                .roysha-icon span:before {
                    color: #fff;
                }

                img {
                    filter: brightness(0) invert(1);
                }
            }
        }
     }
 }

//  Steps
.steps {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
        color: #2f2f35;
        flex-basis: 25%;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        text-transform: capitalize;
        position: relative;

        @include media(767) {
            font-size: 14px;
        }
        @include media(575) {
            font-size: 12px;
            flex-basis: 50%;
        }

        &:not(:first-child):before {
            @include pseudo($t:15px, $l:-10px);
            background-color: #f5f5f5;
            border-top: 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;
            height: 5px;
            width: 45%;
        }
        &:not(:last-child):after {
            @include pseudo($t:15px, $r:-10px, $l:auto);
            background-color: #f5f5f5;
            border-top: 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;
            height: 5px;
            width: 45%;
        }

        @include media(575) {
            margin-bottom: 30px;
            
            &:nth-child(even) {
                &:after {
                    content: none;
                }
            }
            &:nth-child(odd) {
                &:before {
                    content: none;
                }
            }
        }

        span {
            background-color: #fff;
            border: 1px solid #e0e0e0;
            border-radius: 50%;
            color: #6c6c6f;
            display: block;
            font-size: 16px;
            line-height: 35px;
            margin: 0 auto 25px;
            position: relative;
            height: 35px;
            width: 35px;

            &:before {
                @include pseudo($t:-6px, $l:-6px);
                background-color: #f5f5f5;
                border: 1px solid #dfdfdf;
                border-radius: 50%;
                height: 45px;
                width: 45px;
                z-index: -1;
            }           
        }

        p {
            color: #6c6c6f;
            font-weight: 400;
        }
    }

    &.vertical {
        display: block;
        
        li {
            text-align: left;
            padding-left: 67px;

            &:before {
                content: none;
            }

            &:after {
                border: none;
                border-left: 1px solid #e0e0e0;
                border-right: 1px solid #e0e0e0;
                top: 41px;
                left: 20px;
                height: 100%;
                width: 5px;
                z-index: -2;
            }

            span {
                margin: 0;
                position: absolute;
                left: 5px;
                top: 5px;
                text-align: center;
            }
        }
    }
}

 /**
 * Landing overview section
 */
.landing-overview {
    .boxed-shadow {
        padding-left: 20px;
        padding-right: 20px;

        @include media(991) {
            margin-bottom: 30px;
        }
    }

    .no-gutters {
        box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.05); 
        .iconBox {
            box-shadow: none;
            transition: $transition;
            p {
                transition: $transition;
            }
            .roysha-icon span::before {
                transition: $transition;
            }
            &:hover {
                background-color: #2e6de0;

                .roysha-icon span:before { 
                    color: #fff !important; 
                }

                img {
                    filter: brightness(0) invert(1);
                }
                h5 a, p {
                    color: #fff;
                }
            }            
        }
    }
}


 /**
 * System works section
 */
.system-works {
    .cmn-tab-nav {
        max-width: 565px;
        margin: 0 auto 100px;
    }
}



// Common tab navigation
.cmn-tab-nav {
    border: 1px solid #dadada;
    border-radius: 5px;
    display: block;
    position: relative;
    overflow: hidden;

    @include min-media(576) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    li {
        display: block;
        @include min-media(576) {
            flex: 1;
        }

        a {
            background-color: #f7faff;
            @include min-media(576) {
                border-right: 1px solid #dadada;
            }            
            @include media(575) {
                border-bottom: 1px solid #dadada;
            }            
            color: #2f2f35;
            font-weight: 500;
            display: block;
            text-align: center;
            text-transform: capitalize;
            padding: 10px;

            &.active {
                background-color: #256be6;
                color: #fff;
            }
        }

        &:last-child {
            a {
                border: none;
            }
        }        
    }
}

// List tab nav
.list-tab-nav {
    border-color: #dadada;
    justify-content: space-between;
    @include media(575) {
        border: none;
    }

    li {
        flex: 1;
        @include media(575) {
            flex-basis: 100%;
        }

        a {
            border-bottom: 3px solid transparent;
            color: #2f2f35;
            display: block;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            padding-bottom: 20px;

            @include media(767) {
                font-size: 13px;
            }
            @include media(575) {
                border-bottom: 1px solid #dadada;
                padding: 10px 0;
            }

            &.active {
                border-color: #256be6;
                color: #256be6;
            }
        }
    }
}

/**
 * Benefits section
 */
.benefits {
    .list-tab-nav  {
        margin-bottom: 80px;
    }

    .video-box {
        @include media(767) {
            margin-bottom: 50px;
        }
    }
}

/**
 * Fun facts section
 */
.funfacts {
    @include overlay( $i:'../images/banner/13.jpg');
    @include bg-props;

    .sec-heading {
        h2, p {
            color: #fff;
        }
    }

    .btn {
        @include media(479) {
            display: block;
        }
        
        &:first-of-type {
            margin-right: -25px;
            position: relative;
            z-index: 1;
        
            @include media(479) {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
    }

    .fun-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 140px;

        li {
            color: #fff;
            flex: 1;
            text-align: center;

            @include media(575) {
                flex-basis: 50%;
                margin-bottom: 30px;
            }

            span {
                display: block;
                font-size: 30px;
                font-weight: 500;
            }
        }
    }
}

/**
 * Package section
 */
.package {
    position: relative;
}

// Package Table
.package-table {
    table {
        width: 100%;
    }

    th {
        background-color: #256be6;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        text-transform: capitalize;
        padding: 15px 20px;

        @include media(575) {
            font-size: 15px;
        }
        @include media(400) {
            padding: 15px;
        }


        &:first-of-type {
            border-top-left-radius: 5px;
        }
        &:last-of-type {
            border-top-right-radius: 5px;
        }
        &:not(:first-of-type) {
            text-align: center;
        }
    }

    td {
        border: 1px solid #e5e5e5;
        color: #2f2f35;
        padding: 15px 20px;

        @include media(575) {
            font-size: 14px;
        }
        @include media(400) {
            padding: 15px;
        }
        
        &:not(:first-of-type) {
            text-align: center;
        }

        .fa-check-circle {
            color: #2dbe61;
        }
        .fa-times-circle {
            color: #f53212;
        }
    }
}

/**
 * About overview section
 */
.about-overview {
    .iconBox {
        @include media(991) {
            margin-bottom: 30px;
        }
    }
    .boxed-shadow {
        border: 1px solid #f1f1f1;
        padding-left: 20px;
        padding-right: 20px;
    }
}

/**
 * Mission & Vision section
 */
.mission-vision {
    .imgBox {
        @include media(575) {
            width: calc(100% - 30px);
        }
        &:before {
            @include pseudo($t: auto, $l:auto, $b:-30px, $r:-30px);
            background-color: #d8d8d8;
            width: 100%;
            height: 100%;
            z-index: -1;
        }
    }
}

/**
 * Banking partners section
 */
.bank-partners {
    .bankImg {
        margin-bottom: 30px;
    }

    .memb-info {
        position: relative;
    }
}

/**
 * Team section
 */
.team {
    .team-member {
        box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.05);
        position: relative;
        transition: $transition;
        
        @include media(991) {
            margin-bottom: 30px;
        }

        &:hover {
            box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
            margin-top: -5px;
        }

        .memb-thumb {
            display: block;
            width: 100%;
        }
        .memb-info {
            background-color: #fff;
            display: block;
            text-align: center;
            padding: 15px;

            h6, a {
                color: #2f2f35;
                font-size: 20px;
            }

            span {
                color: #6c6c6f;
            }
        }
    }
}

/**
 * Faq page section
 */
.faq-page {
    .form-field:first-of-type {
        flex-basis: 100% ;
    }
    .form-txt {
        color: #fff;
        display: block;
        text-align: center;
        width: 100%;
    }
}

/**
 * Contact section
 */
.contact-from-wrap {
    background-color: #fff;
    box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
    padding: 40px;

    .sec-heading {
        text-align: left;
        margin-bottom: 32px;
    }

    .contact-form {
        .form-group {
            margin-bottom: 33px;
        }

        label {
            color: #2f2f35;
            display: block;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 8px;
        }

        .form-control {
            border-color: #dadada;
            padding: 15px 20px;
            height: auto;
        }

        .btn {
            max-width: 150px;
            min-width: auto;
            width: 100%;
            float: right;
        }
    }
}

.contact-info-wrap {
    background-color: #256be6;
    font-size: 15px;
    padding: 100px 40px;

    address {
        color: #fff;
        padding-left: 65px;
        margin-bottom: 50px;
        position: relative;

        span {
            display: block;
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 7px;
        }

        a {
            color: #fff;
            display: block;
        }

        i {
            background-color: #fff;
            border-radius: 50%;
            color: #256be6;
            font-size: 16px;
            line-height: 40px;
            text-align: center;
            position: absolute;
            left: 0;
            top: 0;
            height: 40px;
            width: 40px;
        }
    }
}

/**
 * Contact Media section
 */
.contact-media {
    .cm-box {
        background-color: #fff;
        border: 1px solid #dadada;
        border-radius: 5px;
        color: #2f2f35;
        display: block;
        font-size: 16px;
        font-weight: 500;
        line-height: .8;
        text-align: center;
        transition: $transition;
        padding: 30px 15px;

        @include media(991) {
            margin-bottom: 30px;
        }

        i {
            display: block;
            font-size: 28px;
            margin-bottom: 20px;
            transition: $transition;

            &[class*="facebook"] {
                color: #3b5999;
            }
            &[class*="twitter"] {
                color: #1da1f2;
            }
            &[class*="instagram"] {
                color: #316d8c;
            }
            &[class*="vimeo"] {
                color: #1ab7ea;
            }
            &[class*="youtube"] {
                color: #eb1d1d;
            }
            &[class*="linkedin"] {
                color: #0077b5;
            }
        }

        &:hover {
            background-color: #399fed;
            color: #fff;

            i { color: #fff; }
        }
    }
}

/**
 * Blog Post section
 */
.post-item {
    border-bottom: 1px solid #dadada;
    margin-bottom: 40px;
    padding-bottom: 30px;
    position: relative;

    h3 {
        color: #262934;
        font-size: 30px;
        margin-bottom: 14px;

        @include media(767) {
            font-size: 25px;
        }

        a {
            color: #262934;
            display: block;
        }
    }

    p { 
        color: #2f2f35;
        line-height: 30px;
        margin-bottom: 0;
    }

    .post-thumb {
        margin-bottom: 23px;
    }

    .post-meta {
        position: relative;
        margin-bottom: 9px;

        span, a {
            color: #6c6c6f;
            display: inline-block;
            font-size: 14px;
            font-weight: 400;
        }
        a {
            color: #004bc9;
            display: inline-block;
            font-weight: 500;
        }
    }

    .btn-default {
        display: inline-block;
        padding: 18px 30px;
        margin-top: 20px;
        min-width: auto;
    }

    // .btn {
    //     background-color: #e2edff;
    //     color: #1577df;
    //     min-width: auto;
    //     margin: 0;
    //     margin-top: 20px;
    // }

}
.publish-date {
    color: #6c6c6f;
    display: inline-block;
    font-size: 14px;
}

// Pagination
.pagination {
    align-items: center;
    text-align: center;
    position: relative;
    
    @include media(767) {
        margin-bottom: 50px;
    }
    @include media(479) {
        display: block;
    }

    a:not(.prev):not(.next),
    .current {
        background-color: #e6e6e6;
        border-radius: 5px;
        color: #1577df;
        display: inline-block;
        line-height: 45px;
        text-align: center;
        margin: 0 3px;
        height: 45px;
        width: 45px;
    }

    a:hover, .current {
        background-color: #1577df !important;
        color: #fff !important;
    }

    .prev, 
    .next {
        border: 1px solid #1577df;
        border-radius: 5px;
        font-weight: 500;
        color: #1577df;
        text-align: center;
        padding: 8px 15px;
        height: 45px;
        width: 120px;

        &:hover {
            background-color: #1577df;
            color: #fff;
        }

        @include media(479) {
            display: block;
            margin-top: 30px;
            // margin: 10px auto ;
            // width: 100%;
        }
    }

    .prev {
        margin-right: auto;
        @include media(479) {
            position: absolute;
            bottom: 0;
        }
    }
    .next {
        margin-left: auto;
    }
    
}


// Breadcrumb 
.breadcrumb {
    background-color: transparent;
    justify-content: center;

    a, span {
        color: #fff;
        display: inline-block;
        text-transform: capitalize;
        padding: 0 15px;
        position: relative;
    }

    a:after {
        content: '\f105';
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        position: absolute;
        right: 0;
        top: 2px;
    }
}

// Sidebar 
.sidebar {
    position: relative;

    .widget {
        margin-bottom: 60px;
        position: relative;

        .widget-title {
            color: #2f2f35;
            font-size: 24px;
            margin-bottom: 24px;
        }
    }

    .widget_search {
        form {
            display: block;
            position: relative;
            width: 100%;

            input {
                border: 1px solid #f1f1f1;
                border-radius: 5px;
                display: block;
                padding: 15px 60px 15px 20px;
                width: 100%;
            }

            i {
                cursor: pointer;
                position: absolute;
                top: 50%;
                right: 25px;
                transform: translateY(-50%);
            }
        }
    }

    .widget_categories {
        ul {
            li {
                border-bottom: 1px solid #dadada;
                position: relative;

                a {
                    color: #2f2f35;
                    display: flex;
                    text-transform: capitalize;
                    padding: 10px 0;

                    span {
                        color: #6c6c6f;
                        display: inline-block;
                        margin-left: auto;
                    }

                    &:after {
                        @include pseudo($t: auto);
                        background-color: $clrblue;
                        transition: $transition;
                        height: 1px;
                        width: 0;
                    }

                    &:hover {
                        color: #1577df;
                        &::after {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    
    .widget_recent_entries {
        ul {
            li {
                border-bottom: 1px solid #dadada;
                margin-bottom: 30px;
                padding-bottom: 30px;
                position: relative;

                a {
                    color: #2f2f35;
                    display: block;
                    font-size: 18px;
                    line-height: 28px;

                    &:after {
                        @include pseudo($t: auto);
                        background-color: $clrblue;
                        transition: $transition;
                        height: 1px;
                        width: 0;
                    }

                    &:hover {
                        color: #1577df;
                        &::after {
                            width: 100%;
                        }
                    }

                    @include media(767) {
                        font-size: 16px;
                    }
                }

                span {
                    display: block;
                }
            }
        }
    }

    .tag-cloud {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;

        a {
            border: 1px solid #dadada;
            border-radius: 5px;
            color: #6c6c6f;
            flex-basis: calc(100% / 3 - 5px);
            text-align: center;
            padding: 10px;
            margin-right: 5px;
            margin-bottom: 5px;

            // &:nth-child(3n - 3) {
            //     margin-right: 0;
            // }

            &:hover {
                border-color: #1577df;
                background-color: #1577df;
                color: #fff;
            }

            @include media(991) {
                font-size: 14px;
            }
        }
    }
}

/**
 * Blog single post section
 */
.blog-single-post {
    .post-item {
        border: none;
        padding-bottom: 0;

        p {
            margin-bottom: 20px;
        }
    }
}

// Social Share
.social-share {
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    padding: 15px 0;
    margin-bottom: 50px;

    label {
        color: #2f2f35;
        font-size: 18px;
        font-weight: 500;
        margin-right: 20px;
        margin-bottom: 0;
    }

    a {
        background-color: #ddd;
        border-radius: 50%;
        color: #fff;
        display: inline-block;
        line-height: 40px;
        text-align: center;
        margin: 0 7px;
        height: 40px;
        width: 40px;

        @include media(479) {
            font-size: 14px;
            line-height: 30px;
            margin: 0 4px;
            height: 30px;
            width: 30px;
        }
    }
}


.comments {
    margin-bottom: 50px;

    .comment-title {
        color: #2f2f35;
        padding-bottom: 0;
        margin-bottom: 30px;
    }
    
    ul {
        list-style: none;

        li {
            position: relative;
            margin-bottom: 34px;

            h4 {
                color: #2f2f35;
                font-size: 18px;
                margin-bottom: 4px;	
            }

            a {
                color: #1577df;
                display: inline-block;
                font-size: 14px;
            }

            a:first-of-type {
                padding-left: 0;
            }
        }
    }

    p {
        color: #2f2f35;
        margin-bottom: 10px;

        @include media(575) {
            font-size: 14px;
        }
    }

    .single-comment {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 30px;
        position: relative;
    }
    .comments-body {
        border-bottom: 1px solid #dadada;
        flex-basis: calc(100% - 80px);
        padding-bottom: 25px;
    }
    .user-thumb {
        border-radius: 50%;
        flex-basis: 60px;
    }
}

// Comment From
.comment-form {
    @include min-media(361) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    @include media(767) {
        margin-bottom: 50px;
    }

    .user-thumb {
        border-radius: 50%;
        flex-basis: 60px;
        width: 60px;

        @include media (360) {
            margin-bottom: 20px;
        }
    }

    form {
        flex-basis: calc(100% - 80px);
        
        textarea {
            background-color: #f6f6f6;
            border: 1px solid #dadada;
            border-radius: 5px;
            margin-bottom: 25px;
            padding: 20px;
            width: 100%;
        }

        .btn {
            display: block;
            min-width: auto;
            margin: 0;
            margin-left: 0px;
            padding-left: 40px;
            padding-right: 40px;
        }
    }
}

/**
 * 404 Error section
 */
.error-page {
    .header {
        background-color: #256be6;
        position: relative;
    }
    .sec-heading {
        margin-bottom: 40px;
    }
}


/**
 * Signup Login form section
 */

.signup-login {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    .sl-slider-caro {
        background-color: #256be6;
        position: relative;
        padding: 10% 0 5%;

        @include min-media(992) {
            flex-basis: 54.687%;
            max-width: 54.687%;
        }
        @include media(991) {
            order: 1;
        }

        &:before {
            background-color: #1b5ccf;
            clip-path: polygon(0 70%, 40% 0, 60% 0, 100% 70%, 100% 100%, 0 100%);
            @include pseudo($t:auto, $b:0);
            height: 50%;
            width: 100%;
            z-index: 0;
        }

        .single-sl-slide {
            img {
                max-width: 763px;
                margin: 0 auto 40px;
            }
        }
        .content-box {
            max-width: 495px;
            margin: 0 auto;
            text-align: center;

            h2, p {
                color: #fff;
            }
        }
        .owl-dots {
            text-align: center;
            position: relative;

            span {
                background-color: #134090;
                border-radius: 50%;
                display: inline-block;
                margin: 0 2px;
                height: 10px;
                width: 10px;
            }
            .active {
                span {
                    background-color: #fff;
                }
            }
        }
    }

    .sl-form-wrap {
        padding: 50px 80px;

        @include min-media(992) {
            flex-basis: 45.312%;
            max-width: 45.312%;
        }
        @include media(991) {
            order: 0;
            width: 100%;
        }
        @include media(575) {
            padding-left: 40px;
            padding-right: 40px;
        }
        @include media(360) {
            padding-left: 15px;
            padding-right: 15px;
        }
        
        header {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 100px;
        }

        .logo {
            display: inline-block;
            max-width: 138px;
        }

        .mbl-menu-toggler {
            color: #262934
        }

        .content-box {
            @include media(991) {
                text-align: center;
            }
            @include media(767) {
                br {
                    display: none;
                }
            }
        }
    }

    .sl-form {
        max-width: 450px;
        width: 100%;

        @include media(991) {
            margin: 0 auto;
        }

        .form-group {
            position: relative;
            margin-bottom: 30px;

            label {
                color: #2f2f35;
                display: flex;
                font-weight: 500;
            }

            input:not([type="checkbox"]) {
                border: 1px solid #dadada;
                border-radius: 50px;
                display: block;
                padding: 10px 25px;
                width: 100%;
            }
        }

        .form-check {
            margin-bottom: 30px;
            label {
                color: #6c6c6f;
                a { 
                    color: #2f2f35;
                    
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            input {
                margin-top: 8px;
            }
        }

        .btn {
            margin-bottom: 30px;
            text-transform: uppercase;
        }
        .notice {
            color: #2f2f35;
            a { 
                color: #1a9948;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

// Coming section
.coming-sec {
    @include bg-props;
    @include overlay($i:'../images/banner/14.jpg');
    background-attachment: fixed;
    padding: 11% 0;
    &:before{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #004bc8;
        opacity: .7;
    }
    .bill-form {
        display: block;

        h3 {
            color: #fff;
            font-size: 24px;
            margin-bottom: 50px;
        }
    }

    .sec-heading {
        @include media(575) {
            margin-bottom: 40px;
        }

        h2 {
            color: #fff;
            font-size: 72px;
            line-height: 72px;
            margin-bottom: 30px;

            @include media(767) {
                font-size: 62px;
            }
            @include media(575) {
                font-size: 52px;
                line-height: 60px;
            }
            @include media(479) {
                font-size: 33px;
                line-height: 40px;
            }
        }
        p { color: #fff; }
    }

    #clock {
        color: #fff;
        margin-bottom: 100px;
        p {
            display: inline-block;
            font-size: 14px;
            text-transform: uppercase;
        }
        span {
            background-color: #256be6;
            border-radius: 5px;
            display: block;
            font-size: 30px;
            line-height: 70px;
            margin: 15px 2px 0;
            position: relative;
            height: 70px;
            width: 50px;
            z-index: 1;

            &:after {
                @include pseudo($t:50%);
                background-color: #002563;
                height: 1px;
                width: 100%;
                z-index: -1;
            }
        }
    }
}

/**
 * Receive works
 */
.receive-works {
    .cmn-tab-nav {
        max-width: 770px;
        margin: 0 auto 100px;

        li {
            a {
                position: relative;

                .nav-arrow {
                    // @include pseudo($l:100%, $t:50%);
                    background-color: #dadada;
                    display: block;
                    clip-path: polygon(0 0, 100% 50%, 0 100%);
                    transform: translateY(-50%);
                    transition: $transition;
                    position: absolute;
                    @include min-media(576) {
                        top: 50%;
                        left: 100%;
                    }
                    height: 12px;
                    width: 6px;
                    z-index: 1;

                    @include media(575) {
                        bottom: -9px;
                        top: auto;
                        left: 0;
                        right: 0;
                        transform: translateY(0) rotate(90deg);
                        margin: 0 auto;
                    }

                    &:after {
                        @include pseudo($t:1px);
                        background-color: #f7faff;
                        clip-path: polygon(0 0, 100% 50%, 0 100%);
                        transition: $transition;
                        height: 10px;
                        width: 5px;
                    }
                }

                &.active {
                    .nav-arrow {
                        background-color: #256be6;
                        &:after {
                            background-color: #256be6;
                        }
                    }
                }
            }
        }
    }
}

/**
 * Landing Ecommerce
 */
.landing-ecommerce {
    .boxed-shadow {
        @include media(991) {
            margin-bottom: 0;
        }
    }
}


@media (min-width: 575px) {
    .footer .foo-nav ul{
        display: block !important
    }
    .footer .widget h5 i{
        display: none !important
    }
}
@media screen and (max-width: 575px){
    .footer .foo-nav ul {
        margin-top: 15px
    }
}


// ANimation

img.an1, .rcvMobile {
    position: absolute;
    bottom: 0;
    left: auto;
    max-width: 100%;
}
.home-two img.an1.animg3 {
    position: relative;
}
img.an1.leaf {
    position: relative;
}
img.an1.animg5 {
    top: 100%;
}

.animg1, .animg2{
    transform-origin: 35.7% 79.8%;
}
.an3{
    position: absolute;
    transform-origin: 54% 86%;
    transform: scale(0);
    width: 100%
}
// ANIMATION
.ani4img1, .ani4img2{
    position: absolute;
    top: 0;
    transform-origin: 22.3% 83.7%
}
.ani5:not(.ani5img1){
    position: absolute;
    top: 0
}
.ani7:not(.ani7img1){
    position: absolute;
    top: 0
}
.ani7{
    transform-origin: 47.3% 50%
}